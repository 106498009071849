export default {
  methods: {
    getThumbnailAlt(mime) {
      // text
      if (mime.includes('text/plain')) return 'text';
      // audio
      if (mime.includes('audio/'))  return 'audio';
      // video
      if (mime.includes('video/')) return 'video';
      // pdf
      if (mime.includes('application/pdf')) return 'pdf';
      // powerpoint
      if (mime.includes('application/vnd.ms-powerpoint')) return 'powerpoint';
      if (mime.includes('application/vnd.ms-powerpoint.presentation.macroEnabled.12')) return 'powerpoint';
      if (mime.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation')) return 'powerpoint';
      // word
      if (mime.includes('msword'))  return 'word';
      if (mime.includes('wordprocessing')) return 'word';
      if (mime.includes('vnd.ms-fontobject')) return 'word';
      // excel
      if (mime.includes('spreadsheet')) return 'excel';
      if (mime.includes('vnd.ms-excel')) return 'excel';
      
      return 'file';
    },
    
    getThumbnail(mime) {
      let type = this.getThumbnailAlt(mime);
      if (type === 'text') return '/thumbnail/txt.svg';
      if (type === 'audio') return '/thumbnail/audio-file.svg';
      if (type === 'video') return '/thumbnail/video-file.svg';
      if (type === 'pdf') return '/thumbnail/pdf.svg';
      if (type === 'powerpoint') return '/thumbnail/ppt.svg';
      if (type === 'word') return '/thumbnail/word.svg';
      if (type === 'excel') return '/thumbnail/xls.svg';
      return '/thumbnail/file.svg';
    },
    
    isTypeImage(file) {
     return file.mimetype.includes('image/');
    }
  }
}