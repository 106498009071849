import store from "@store";

export default {
  methods: {
    getDomainUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    getToken() {
      let user = store.state.currentUser;
      if (user && user.jwt) return user.jwt;
      return null;
    },
    generateUrl(path) {
      let domain = this.getDomainUrl();
      let token = this.getToken();
      return domain + path + '?token=' + token;
    },
    generateUrlForFile(file) {
      return this.generateUrl('/file/content/' + file.id);
    },
  }
}